import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { TextField } from '@mui/material';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WalletBalanceDetails from '../../utils/WalletBalanceDetails';
import { toast } from 'react-toastify';
import { getCookie } from './../../utils/Utils';
import { CusTextField } from '../../themes/CusComponent';
import { useTheme } from '../../layout/provider/Theme';
import { TriggerBtnContext } from '../../App';

function PayPalComponent({ onSendChildData }) {
    const { getWalletBalance } = WalletBalanceDetails();
    const [liveTokenValue, setLiveTokenValue] = useState(null);
    const [tokenValueNo, setTokenValueNo] = useState('');
    const [usd, setUsd] = useState(10);
    const theme = useTheme();
    const { toggleFetch } = useContext(TriggerBtnContext);

    useEffect(() => {
        if (getWalletBalance?.data) {
            const tokenValue = getWalletBalance.data.stats.live_token_value;
            setLiveTokenValue(tokenValue);
            const no_of_tokens = usd / tokenValue;
            setTokenValueNo(no_of_tokens.toFixed(6));
        }
    }, [getWalletBalance, usd]);

    const lastSixDecimals = liveTokenValue ? liveTokenValue.toFixed(6) : 'N/A';

    const handleToken = (e) => {
        const value = e.target.value;
        setTokenValueNo(value);
        const USD = value * liveTokenValue;
        setUsd(USD.toFixed(2));
    };

    const handleUsd = (e) => {
        const value = e.target.value;
        setUsd(value);
        const no_of_tokens = value / liveTokenValue;
        setTokenValueNo(no_of_tokens.toFixed(6));
    };

    const onError = (_data, _actions) => {
        toast.error("Please try again.");
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            addPayment(details);
        });
    };
    const addPayment = async (payment_response) => {
        let formData = new FormData();
        formData.append("payment_response", JSON.stringify(payment_response));
        formData.append("mode", 2);
        formData.append("purpose", 1);
        formData.append("buyTokens", 1);
        let fetRes = await fetch(`${process.env.REACT_APP_API_URL}api/v1/payment/add`, {
            method: "POST",
            cache: "no-store",
            body: formData,
            headers: {
                authToken: getCookie("authToken", null),
            },
        });
        const res = await fetRes.json();

        if (res.status) {
            toast.success(res.message);
            onSendChildData(false);
            toggleFetch();
        } else {
            toast.error(res.message);
        }
    };
    const createOrder = (_data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        amount: {
                            currency_code: "USD",
                            value: usd,
                        },
                        payee: {
                            email_address: getCookie("email", "joe@example.com"),
                            merchant_id: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
                        },
                    },
                ],
            })
            .then((orderID) => {
                return orderID;
            });
    };
    return (
        <div className="container-fluid">
            <Row className="g-2">
                <Col xs={12} sm={6} md={12} className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-column'>
                        <CusTextField
                            label="Live Token Rate"
                            variant="standard"
                            value={lastSixDecimals}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                input: {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </div>
                    <div>
                        <CusTextField
                            label="TOKENS"
                            variant="standard"
                            value={tokenValueNo}
                            onChange={handleToken}
                            type='number'
                            sx={{
                                input: {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </div>
                    <div>
                        <CusTextField
                            label="USD"
                            variant="standard"
                            value={usd}
                            onChange={handleUsd}
                            type='number'
                            sx={{
                                input: {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiOutlinedInput-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                },
                                '.MuiInputLabel-root': {
                                    color: theme.skin === 'dark' ? '#fff' : '#000',
                                }
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} className="d-flex justify-content-center">
                    <PayPalScriptProvider
                        options={{
                            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        }}
                    >
                        <PayPalButtons
                            // style={{ layout: "horizontal" }}
                            key={usd}
                            className='w-25'
                            fundingSource="paypal"
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                        />
                    </PayPalScriptProvider>
                </Col>
            </Row>
        </div>
    );
}

export default PayPalComponent;
