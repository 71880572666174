import classNames from 'classnames';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import "./newmenu.css";

const pages = [
    { label: "Dashboard", link: "/" },
    { label: "Transactions", link: "/transactions" },
    { label: "Settings", link: "/settings" },
    // { label: "Support", link: "/support" },
];

const NewMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <button className="menu-toggle" onClick={toggleMenu}>
                {isOpen ? "Close Menu" : "Open Menu"}
            </button>
            <div className={classNames("new-sidebar", { "open": isOpen })}>
                <ul>
                    {pages.map((item, index) => (
                        <li key={index}>
                            <NavLink
                                to={item.link}
                                className={({ isActive }) => classNames({ 'active': isActive })}
                                end
                            >
                                {item.label}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default NewMenu;
