
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_API_URL || `https://api.silocloud.io/`;
const headers = {
    authToken: Cookies.get("authToken", undefined),
    "Content-Type": "application/json",
};


// Example of a GET request
export const getRequest = async (route) => {
    try {
        const response = await fetch(`${BASE_URL}${route}`, {
            method: "GET",
            headers: headers,
        })

        if (!response.ok) {
            const errorData = await response.json();
            const error = new Error("Failed to post data From api-services");
            error.info = errorData;
            error.code = `${response.status}`;
            throw error;
        }
        return await response.json();
    } catch (error) {
        return error.message;
    }
};

export const postRequestDataForm = async (data, route) => {
    // for (const [key, value] of data.entries()) {
    //     console.log(`${key}: ${value}`);
    // }
    try {
        const response = await fetch(`${BASE_URL}${route}`, {
            method: "POST",
            headers: {
                authToken: Cookies.get("authToken", undefined),
            },
            body: data,
        });

        if (!response.ok) {
            const errorData = await response.json();
            const error = new Error("Failed to post data From api-services");
            error.info = errorData;
            error.code = `${response.status}`;
            throw error;
        }
        return await response.json();

    } catch (error) {
        return error.message;
    }
};

export const postRequestData = async (data, route) => {
    try {
        const response = await fetch(`${BASE_URL}${route}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const error = new Error("Failed to post data From api-services");
            error.info = errorData;
            error.code = `${response.status}`;
            throw error;
        }
        return await response.json();

    } catch (error) {
        return error.message;
    }
};

export async function getAllNotificationHandler({ limit = 10, offset = 0, module = 8, recent = 10, view_all = 1 }) {
    const response = await fetch(`${BASE_URL}api/v1/public/get-notifications`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ limit, offset, module, recent }),
    });

    if (!response.ok) {
        const errorData = await response.json();

        const error = new Error("An Error occurred while getting all notifications.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }

    return await response.json();
}

export async function setNotificationCountHandler({ notification_id = 0, status = 1, module = 6 }) {
    const response = await fetch(`${BASE_URL}api/v1/public/set-notification-status`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ module, status, notification_id }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error("An Error occurred while setting notification count.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }

    return await response.json();
}

export async function getProfileImageHandler({ type = "crop" }) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}account/get-profile-img`, {
        method: "POST",
        headers: {
            authToken: Cookies.get("authToken", null),
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ type }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error("An Error occurred while getting profile image.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }

    if (response.status === 200) {
        return await response.blob();
    } else if (response.status === 204) {
        return await response.json();
    }
}

export const fetchCloudSearchApi = async (route) => {
    let response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
        method: "GET",
        cache: "no-store",
        headers: headers,
    });
    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error("An Error occurred while AppDropdown.");
        error.info = errorData;
        error.code = `${response.status}`;
        throw error;
    }
    return await response.json();
};




