import { Box, Button, Grid, Skeleton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { postRequestData } from '../../utils/api-services';
import { toast } from 'react-toastify';
import { CusFiledTitle, CusTitle, Heading, SettingBtn } from '../../themes/CusComponent';
import { useTheme } from '../../layout/provider/Theme';
import Cookies from 'js-cookie';
import AccountAvatar from '../../layout/header/dropdown/user/AccountAvatar';

const Settings = () => {
  const [productionId, setProductionId] = useState('');
  const [sandboxId, setSandboxId] = useState('');
  const [error, setError] = useState({ productionIdErr: false, sandboxIdErr: false });
  const theme = useTheme();

  const [modalSm, setModalSm] = useState(false);
  const toggleSm = () => setModalSm(!modalSm);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Simulate cookie loading
    setTimeout(() => {
      setUsername(Cookies.get('username') || 'John');
      setEmail(Cookies.get('email') || 'example@example.com');
      setLoading(false);
    }, 1000); // Simulating delay, replace with real loading logic
  }, []);

  const validateFields = () => {
    let isValid = true;
    const newError = { productionIdErr: false, sandboxIdErr: false };

    if (!productionId) {
      newError.productionIdErr = true;
      isValid = false;
    }
    if (!sandboxId) {
      newError.sandboxIdErr = true;
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      try {
        const payload = {
          "paypal_production_client_id": productionId,
          "paypal_sandbox_client_id": sandboxId,
        }
        const response = await postRequestData(payload, `api/v1/wallet/paypal-settings`);
        if (response.status) {
          toast.success(`Paypal Client Id Update Request Successfully...`);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        console.log(`An error occurred in Settings page on PayPal Client Id Update: ${error}`);
      }
    }
  };

  return (
    <>
      <Modal size="md" isOpen={modalSm} toggle={toggleSm}>
        <ModalHeader toggle={toggleSm}>
          <p className="fs-6">PayPal Client ID</p>
        </ModalHeader>
        <ModalFooter className="bg-light">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box component="form">
                <TextField
                  fullWidth
                  label="Paypal Production Client Id"
                  color="secondary"
                  size="small"
                  value={productionId}
                  onChange={(e) => setProductionId(e.target.value)}
                  className='mt-2'
                  error={error.productionIdErr}
                  helperText={error.productionIdErr ? "Please enter a valid Production Client ID." : ""}
                />
                <TextField
                  fullWidth
                  label="Paypal Sandbox Client Id"
                  color="secondary"
                  size="small"
                  value={sandboxId}
                  onChange={(e) => setSandboxId(e.target.value)}
                  className='mt-2'
                  error={error.sandboxIdErr}
                  helperText={error.sandboxIdErr ? "Please enter a valid Sandbox Client ID." : ""}
                />
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" className="mt-3">
                  <Button color="secondary" variant="outlined" onClick={handleUpdate}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ModalFooter>
      </Modal>

      <div className="container-fluid overflow-auto custom-scrollbar" style={{ height: `calc(100vh - 121px)` }}>
        <Grid container columnSpacing={2} spacing={0}>
          <Grid item xs={12} sm={6} md={12} sx={{
            borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          }}
          >
            <Heading className={`my-3 ms-5 p-2 text-${theme.skin === "dark" ? "white" : "black"}`}>Account</Heading>
          </Grid>
        </Grid>
        {/* Personal Info Section */}
        <Grid container columnSpacing={2} className=' py-sm-1 py-md-2 py-lg-4' spacing={0} sx={{
          borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
        }}>
          <Grid item xs={12} sm={6} md={12} className='py-1'>
            <CusTitle className='ms-4'>Personal Info</CusTitle>
          </Grid>
          <Grid container direction="row" className='ms-5 m-2'>
            <Grid item xs={12} sm={6} md={1}>
              {/* <AccountAvatar profileName={Cookies.get("username", "John")} /> */}
              {loading ? (
                <Skeleton variant="circular" width={40} height={40} />
              ) : (
                <AccountAvatar profileName={username} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CusFiledTitle>First Name</CusFiledTitle>
              {loading ? (
                <Skeleton variant="rounded" animation="wave" fullWidth />
              ) : (
                <Typography>{username}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CusFiledTitle>E-mail</CusFiledTitle>
              {loading ? (
                <Skeleton variant="rounded" animation="wave" fullWidth />
              ) : (
                <Typography>{email}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={2}
              className='d-flex align-items-end p-2'
              sx={{ gap: 1 }}
            >
              {/* <SuccessBtn size='small' sx={{
                background: theme.skin === "dark" ? '#16393a' : "#59f1f6a9",
                color: theme.skin === "dark" ? '#1ee0ac' : "#000",
              }}>
                Verified
              </SuccessBtn> */}
              <Button size='small' sx={{ color: theme.skin === "dark" ? "#fff" : "#000", background: "#a5a6a91c" }}
                onClick={() =>
                  window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}user-profile-regular`}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} spacing={0}>
          <Grid item xs={12} sm={6} md={12} sx={{
            borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          }}
          >
            <Heading className={`my-3 ms-5 p-2 text-${theme.skin === "dark" ? "white" : "black"}`}>Security</Heading>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} spacing={0} className='py-sm-1 py-md-2 py-lg-4' sx={{
          borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          paddingLeft: "25px"
        }}>
          <Grid item xs={12} sm={6} md={12} className='py-1'>
            <CusTitle>Password</CusTitle>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className='my-2'>
            <SettingBtn onClick={() =>
              window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}user-profile-setting`}>
              Change Password
            </SettingBtn>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} spacing={0} className='py-sm-1 py-md-2 py-lg-4' sx={{
          borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          paddingLeft: "25px"
        }}>
          <Grid item xs={12} sm={6} md={12} className='py-1'>
            <CusTitle>Two-Factor Authentication</CusTitle>
            <Typography sx={{ fontSize: "12px", marginTop: "5px" }}>
              Protect your account and your funds by adding a
              second authentication method. Each time you login or<br />
              initiate a withdrawal, you will be asked for your
              password and authentication code.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className='my-2'>
            <SettingBtn onClick={() =>
              window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}user-profile-setting/two-factor-setting`}>
              Add Two-Factor Now
            </SettingBtn>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} spacing={0} className='py-sm-1 py-md-2 py-lg-4' sx={{
          borderBottom: `1px solid ${theme.skin === "dark" ? "#4b4b4b" : "#D0DEFC"}`,
          paddingLeft: "25px"
        }}>
          <Grid item xs={12} sm={6} md={12} className='py-1'>
            <CusTitle>Address Management</CusTitle>
            <Typography sx={{ fontSize: "12px", marginTop: "5px" }}>
              Only allow withdrawals to addresses you have added to your appproved whitelist.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className='my-2'>
            <SettingBtn onClick={() => setModalSm(true)}>
              Paypal Client ID
            </SettingBtn>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Settings;
