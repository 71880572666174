import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
    TextField,
    InputAdornment,
    Skeleton
} from '@mui/material';
import deposit from "../../images/DashBoard/deposit.png";
import depositw from "../../images/DashBoard/depositw (1).png";
import withdraw from "../../images/DashBoard/withdraw.png";
import withdraww from "../../images/DashBoard/withdraww.png";
import transfer from "../../images/DashBoard/transfer.png";
import transferw from "../../images/DashBoard/transferw.png";
import { useTheme } from "../../layout/provider/Theme.js";
import WalletBalance from "./WalletBalance.js";
import PayPalComponent from "../PayPal/PayPalComponent.js";
import TransferTokens from "../Transfer/TransferTokens.js";
import WithdrawalToken from "../WithdrawalToken/WithdrawalToken.js";
import "./dashboard.css";
import WalletBalanceDetails from "../../utils/WalletBalanceDetails.js";
import { getRequest, postRequestData } from "../../utils/api-services.js";
import { CusAutocomplete, CusTextField } from "../../themes/CusComponent.js";
import { toast } from "react-toastify";

const Dashboard = () => {

    const theme = useTheme();
    const [list, setList] = useState([]);
    const [tradingWallet, setTradingWallet] = useState({ coin: {}, hashKey: "" });
    const [fundingWallet, setFundingWallet] = useState({ coin: {}, hashKey: "" });
    const [isActive, setIsActive] = useState('');
    const [walletType, setWalletType] = useState('Trading Wallet');

    const [modalSm, setModalSm] = useState(false);
    const toggleSm = () => setModalSm(!modalSm);

    const [modalTran, setModalTran] = useState(false);
    const toggleTran = () => setModalTran(!modalTran);

    const [modalWith, setModalWith] = useState(false);
    const toggleWith = () => setModalWith(!modalWith);

    const { getWalletBalance, loading } = WalletBalanceDetails();

    useEffect(() => {
        const fetchList = async () => {
            try {
                const response = await getRequest(`api/v1/wallet/external-wallet-master`);
                if (response.status) {
                    setList(response?.data?.list);
                }
            } catch (error) {
                console.log(`An error occurred while fetching wallets : ${error}`);
            }
        }
        fetchList();

    }, []);

    const handleChildData = (data) => {
        toggleSm();
    }
    const handleTradingCoin = (data) => {
        setTradingWallet((prevState) => ({
            ...prevState,
            coin: data || {}
        }));
    }
    const handleFundingCoin = (data) => {
        setFundingWallet((prevState) => ({
            ...prevState,
            coin: data || {}
        }));
    }

    const handleTradingWallet = async () => {
        try {
            const data = {
                key: walletType === "Trading Wallet"
                    ? tradingWallet?.hashKey
                    : fundingWallet?.hashKey,
                external_wallet_masters_id: walletType === "Trading Wallet"
                    ? tradingWallet?.coin?.id
                    : fundingWallet?.coin?.id,
                type: walletType === "Trading Wallet"
                    ? "trading_wallet_key"
                    : "funding_wallet_key",
            }
            const res = await postRequestData(data, "api/v1/wallet/external-wallet");
            if (res.status) {
                toast.success(res.message);
                setFundingWallet({ coin: {}, hashKey: "" });
                setTradingWallet({ coin: {}, hashKey: "" });
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.log(`An error occurred while set wallets : ${error}`);
        }
    }

    return (
        <div className="container-fluid px-4 py-2 ">
            <Row className="">
                <WalletBalance />
                <Col xs={12} sm={6} md={8}>
                    <Card>
                        <CardBody className="rounded-5 side-shadow" style={{ height: "6.9rem" }}></CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal size="lg" isOpen={modalSm} toggle={toggleSm}>
                <ModalHeader toggle={toggleSm}>
                    <p className="fs-6">PayPal</p>
                </ModalHeader>
                <ModalFooter className="bg-light">
                    <PayPalComponent onSendChildData={handleChildData} />
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={modalTran} toggle={toggleTran}>
                <ModalHeader toggle={toggleTran}>
                    <p className="fs-6">Transfer Tokens</p>
                </ModalHeader>
                <ModalFooter className="bg-light">
                    <TransferTokens />
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={modalWith} toggle={toggleWith}>
                <ModalHeader toggle={toggleWith}>
                    <p className="fs-6">Withdrawal Tokens</p>
                </ModalHeader>
                <ModalFooter className="bg-light">
                    <WithdrawalToken />
                </ModalFooter>
            </Modal>

            <Grid container columnSpacing={2} spacing={1} className="mt-1">
                <Grid item xs={12} sm={6} md={4}>
                    <Box display="flex" justifyContent="space-between" sx={{ gap: 1 }}>
                        <Button
                            className={`wallet-button deposit-h ${isActive === 'deposit' ? 'active' : ''}`}
                            onClick={() => { setModalSm(true); setIsActive('deposit'); }}
                            startIcon={<img src={theme.skin === "dark" ? depositw : deposit} alt="Deposit"
                            />}
                        >
                            Deposit
                        </Button>
                        <Button
                            className={`wallet-button withdraw-h ${isActive === 'withdrawal' ? 'active' : ''}`}
                            onClick={() => { setModalWith(true); setIsActive('withdrawal'); }}
                            startIcon={<img src={theme.skin === "light" ? withdraw : withdraww} alt="Withdraw" />}
                        >
                            Withdraw
                        </Button>
                        <Button
                            className={`wallet-button transfer-h ${isActive === 'transfer' ? 'active' : ''}`}
                            onClick={() => { setModalTran(true); setIsActive('transfer'); }}
                            startIcon={<img src={theme.skin === "light" ? transfer : transferw} alt="Transfer" />}
                        >
                            Transfer
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={0} className="mt-2 d-flex justify-content-between">
                <Grid item xs={12} sm={6} md={5.9} className="p-0">
                    <Box borderRadius={3} p={2}
                        sx={{ backgroundColor: theme.skin === "light" ? "#FAFDFF" : "#a5a6a91c" }}>
                        <Typography className="field-title mb-1">Trading Wallet</Typography>
                        <Box display="flex" flexDirection="row" >
                            <CusAutocomplete
                                options={list}
                                className="me-1"
                                fullWidth
                                size="small"
                                clearIcon={null}
                                getOptionLabel={(option) => (option?.name || "")}
                                value={tradingWallet?.coin || null}
                                renderOption={(props, option) => (
                                    <li {...props}
                                        onClick={() => handleTradingCoin(option)}>
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}${option.logo}`}
                                            alt={option.name}
                                            style={{ width: 24, height: 24, marginRight: 8 }}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="Search and Select"
                                        placeholder="Select"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        color: theme.skin === 'dark' ? '#fff' : '#000',
                                        
                                    },
                                    '& .MuiAutocomplete-popupIndicator': {
                                        color: theme.skin === "light" ? "#000" : "#fff",
                                    },
                                    '& .MuiAutocomplete-clearIndicator': {
                                        color: theme.skin === "light" ? "#000" : "#fff",
                                    },
                                    
                                }}
                            />
                            <CusTextField
                                variant="outlined"
                                // label="Wallet ID"
                                placeholder="Wallet ID"
                                size="small"
                                value={tradingWallet?.hashKey}
                                fullWidth
                                onChange={(e) => {
                                    setTradingWallet((prevState) => ({
                                        ...prevState,
                                        hashKey: e.target.value
                                    }));
                                }}
                                sx={{
                                    mr: 1,
                                    '.MuiOutlinedInput-root': {
                                        backgroundColor: 'transparent',
                                        color: theme.skin === 'dark' ? '#fff' : '#000',
                                        '& input::placeholder': {
                                            color: theme.skin === 'light' ? "" : '#fff',
                                            opacity: 0.4,
                                        },
                                    },
                                    '.MuiInputLabel-root': {
                                        color: theme.skin === 'dark' ? '#fff' : '#000',
                                    },
                                }}
                            />
                            <Button variant="contained" size="small" className="save-h shadow-none"
                                onClick={() => {
                                    handleTradingWallet();
                                    setWalletType('Trading Wallet')
                                }}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="p-0 mt-1 mt-sm-0">
                    <Box borderRadius={3} p={2}
                        sx={{ backgroundColor: theme.skin === "light" ? "#FAFDFF" : "#a5a6a91c", height: '100%' }}>
                        <Typography className="field-title mb-1">Funding Wallet</Typography>
                        <Box display="flex" flexDirection="row" >
                            <CusAutocomplete
                                options={list}
                                className="me-1"
                                fullWidth
                                size="small"
                                clearIcon={null}
                                getOptionLabel={(option) => (option?.name ? option.name : '')}
                                value={fundingWallet?.coin || null}
                                renderOption={(props, option) => (
                                    <li {...props}
                                        onClick={() => handleFundingCoin(option)}>
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}${option.logo}`}
                                            alt={option.name}
                                            style={{ width: 24, height: 24, marginRight: 8 }}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="Search and Select"
                                        placeholder="Select"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        color: theme.skin === 'dark' ? '#fff' : '#000',
                                    },
                                    '& .MuiAutocomplete-popupIndicator': {
                                        color: theme.skin === "light" ? "#000" : "#fff",
                                    },
                                    '& .MuiAutocomplete-clearIndicator': {
                                        color: theme.skin === "light" ? "#000" : "#fff",
                                    },
                                }}
                            />
                            <CusTextField
                                variant="outlined"
                                // label="Wallet ID"
                                placeholder="Wallet ID"
                                size="small"
                                fullWidth
                                value={fundingWallet?.hashKey}
                                onChange={(e) => {
                                    setFundingWallet((prevState) => ({
                                        ...prevState,
                                        hashKey: e.target.value
                                    }));
                                }}
                                sx={{
                                    mr: 1,
                                    '.MuiOutlinedInput-root': {
                                        backgroundColor: 'transparent',
                                        color: theme.skin === 'dark' ? '#fff' : '#000',
                                        '& input::placeholder': {
                                            color: theme.skin === 'light' ? "" : '#fff',
                                            opacity: 0.4,
                                        },
                                    },
                                    '.MuiInputLabel-root': {
                                        color: theme.skin === 'dark' ? '#fff' : '#000',
                                    }
                                }}
                            />
                            <Button variant="contained" size="small" className="save-h shadow-none"
                                onClick={() => {
                                    handleTradingWallet();
                                    setWalletType('Funding Wallet')
                                }}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2} className="mt-2">
                <Grid item xs={12} sm={8}>
                    <Box
                        sx={{
                            backgroundColor: theme.skin === "light" ? "#FAFDFF" : "#a5a6a91c",
                            borderRadius: 3,
                            padding: 1.4,
                        }}
                    >
                        <Typography className="field-title fs-5">SBC</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Paper sx={{ padding: 2, borderRadius: 3 }} className="bg-light">
                                    <Typography className="card-te" gutterBottom>Live Token Price</Typography>
                                    <Typography className="fs" fontWeight="bold">
                                        {
                                            !loading
                                                ? getWalletBalance?.data?.stats?.live_token_value.toFixed(6)
                                                : <Skeleton variant="rounded" animation="wave" fullWidth />
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Paper sx={{ padding: 2, borderRadius: 3 }} className="bg-light">
                                    <Typography className="card-te" gutterBottom>Network Commission</Typography>
                                    <Typography className="fs" fontWeight="bold">
                                        {
                                            !loading
                                                ? getWalletBalance?.data?.stats?.network_commision.toFixed(2)
                                                : <Skeleton variant="rounded" animation="wave" fullWidth />
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Paper sx={{ padding: 2, borderRadius: 3 }} className="bg-light">
                                    <Typography className="card-te" gutterBottom>Social Network Points</Typography>
                                    <Typography className="fs" fontWeight="bold">
                                        {
                                            !loading
                                                ? getWalletBalance?.data?.stats?.social_network_points.toFixed(2)
                                                : <Skeleton variant="rounded" animation="wave" fullWidth />
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Paper sx={{ padding: 2, borderRadius: 3 }} className="bg-light">
                                    <Typography className="card-te" gutterBottom>User’s Token Value</Typography>
                                    <Typography className="fs d-flex" fontWeight="bold">
                                        ${
                                            !loading
                                                ? getWalletBalance?.data?.stats?.tokens_usd_value.toFixed(2)
                                                : <Skeleton variant="rounded" animation="wave" fullWidth />
                                        }
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            backgroundColor: theme.skin === "light" ? "#FAFDFF" : "#a5a6a91c",
                            p: 3,
                            borderRadius: 3,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                        }}
                    >
                        <Grid container sx={{ borderBottom: "1px solid #D0DEFF", mb: 1, pb: 1 }}>
                            <Grid item xs={7}>
                                <Typography className="field-title" gutterBottom>24Hr Price</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className="field-title">
                                    {
                                        !loading ?
                                            <>
                                                {getWalletBalance?.data?.stats?.average_24_hrs_token_value.toFixed(2)}
                                                &nbsp; %
                                            </>
                                            : <Skeleton animation="wave" fullWidth />
                                    }

                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ borderBottom: "1px solid #D0DEFF", mb: 1, pb: 1 }}>
                            <Grid item xs={7}>
                                <Typography className="field-title" gutterBottom>24Hr Volume</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className="field-title">
                                    {
                                        !loading ?
                                            <>
                                                {Number(getWalletBalance?.data?.stats?.average_24_hrs_token_transaction_volume.toFixed(2)).toLocaleString('en-US')}
                                                &nbsp; SBC
                                            </>
                                            : <Skeleton animation="wave" fullWidth />
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={7}>
                                <Typography className="field-title" gutterBottom>Active Traders</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className="field-title">
                                    {
                                        !loading
                                            ? getWalletBalance?.data?.stats?.no_of_24_hrs_transaction_traders
                                            : <Skeleton animation="wave" fullWidth />
                                    }

                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};

export default Dashboard;
