import { Autocomplete, Button, Grid, styled, Switch, TableBody, TableContainer, TableHead, TextField, Typography } from '@mui/material';
import { theme } from './index';

// Heading component
export const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem', // Default size
    fontWeight: 500,
    // color: theme.palette.mode === 'light' ? "#000" : "#fff",
    lineHeight: "24px",
    letterSpacing: "0.625px",
    padding: theme.spacing(1),
}));

// Title component
export const CusTitle = styled(Typography)(({ theme }) => ({
    fontSize: '18px', // Default size
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0.625px",
    // padding: theme.spacing(1),
}));

// NormalText component
export const CusFiledTitle = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: "16px",
    // padding: theme.spacing(1),
}));

export const CustomButton = styled(Button)(({ theme }) => ({
    transition: 'background-color 0.3s ease',
    backgroundColor: 'transparent',
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.mode === 'light' ? '#000000' : '#fff',
    border: '1px solid #D0DEFC',
    '&:hover': {
        backgroundColor: '#D0DEFC',
        color: '#000',
        border: '1px solid #D0DEFC',
    },
    '&:active': {
        backgroundColor: '#D0DEFC',
        color: '#000',
        border: '1px solid #D0DEFC',
    },
}));

export const SuccessBtn = styled(Button)(({ theme }) => ({
    transition: 'background-color 0.3s ease',
    // backgroundColor: '#16393a',
    fontSize: '13px',
    fontWeight: 500,
    // color: "#1ee0ac",
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#1ee0ac',
        color: '#000',
    },
    '&:active': {
        backgroundColor: '#1ee0ac',
        color: '#000',
    },
}));
export const SettingBtn = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.5rem',
    lineHeight: '2.5rem',
    // width: '100%',
    width: 'max-content',
    padding: '0px 1rem',
    boxSizing: 'border-box',
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(12, 108, 242)',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    outline: 'none',
    transition: '0.3s',
    opacity: 1,
    fontSize: '0.9rem',
    fontWeight: 600,
    textTransform: 'none',

    // Hover effect
    '&:hover': {
        backgroundColor: 'rgb(0, 104, 175)',
        transform: 'none',
    },

    // Responsive styles for smaller screens
    [theme.breakpoints.down('md')]: {
        width: 'auto',
        padding: '0.75rem 1.5rem',
        fontSize: '0.875rem',
    },

    [theme.breakpoints.down('sm')]: {
        width: '100%', // Full width on small screens
        padding: '0.5rem 1rem',
        fontSize: '0.75rem',
        borderRadius: '6px',
    },

    [theme.breakpoints.down('xs')]: {
        padding: '0.5rem 0.75rem',
        fontSize: '0.7rem',
        height: '2.5rem',
        lineHeight: '1.5rem',
    },
}));
export const CusTableHead = styled(TableHead)(({ theme }) => ({

    '& .MuiTableCell-root': {
        fontWeight: 500,
        fontSize: '12px',
        borderBottom: "1px solid #D0DEFC",
        borderTop: "1px solid #D0DEFC",
        backgroundColor: 'transparent',
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
}));

export const CusTableBody = styled(TableBody)(({ theme }) => ({
    '& .MuiTableCell-root': {
        fontWeight: 400,
        fontSize: '12px',
    },
}));

export const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    '&::-webkit-scrollbar': {
        width: '5px',
    },
    '&::-webkit-scrollbar-track': {
        background: "#000", // Replace with your variable
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#000',
    },
    '& .MuiTable-root': {
        color: "#fff", // Ensures the table itself inherits white text color
    },
    '& .MuiTableCell-root': {
        color: theme.palette.mode === "light" ? "#000" : "#fff", // Ensures table cell content is white
        borderColor: "#D0DEFC", // Optionally set border color to white for table cells
    },
    position: 'sticky', // Makes the container sticky
    top: 0, // Positioning the sticky container at the top
    maxHeight: 'calc(100vh - 51vh)', // Adjust height as needed
    overflow: 'auto', // Ensures scrollability when content exceeds height
    zIndex: 1, // Ensures it stays above other content when sticky
    backgroundColor: 'transparent',
}));


export const CusAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
        color: theme.palette.mode === "light"
            ? "#000"
            : "#fff",
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#D0DEFC",
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#e14954",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#e14954",
    },
    // Set the arrow icon color
    '& .MuiAutocomplete-popupIndicator': {
        color: theme.palette.mode === "light" ? "#000" : "#fff",
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: theme.palette.mode === "light" ? "#000" : "#fff",
    },
    '& .MuiFormLabel-root': {
        color: theme.palette.mode === "light" ? "#000" : "#fff",
    },
    
}));
export const CusAutoNoBg = styled(Autocomplete)(({ theme }) => ({
    background: "#a5a6a91c",
    borderRadius: 3,
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none",
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: "1px solid",
        borderColor: "#e14954",

    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: "1px solid",
        borderColor: "#e14954",
    },
    '& .MuiAutocomplete-paper': {
        backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
}));

export const CusTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#D0DEFC',
        },
        '&:hover fieldset': {
            borderColor: '#e14954',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#e14954',
        },
        '& input::placeholder': {
            color: theme.palette.mode === 'light' ? '#000' : '#fff',
            opacity: 1,
        },
    },
    '& .MuiInputLabel-root': {
        color: theme.palette.mode === "light" ? '#000' : '#fff',
        '&.Mui-focused': {
            color: '#e14954',
        },
        '&:hover': {
            color: '#e14954',
        },
    },
}));

export const CustomGrid = styled(Grid)(({ theme }) => ({
    // backgroundColor: theme.skin === "light" ? "#FAFDFF" : "#a5a6a91c",
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '12px', // equivalent to borderRadius={3}

    padding: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),

    // Responsive styles
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));



